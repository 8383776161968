<template>
<div>
 <v-scale-screen :fullScreen="false" width="1920" height="1080">
  <div class="newIndustryScreen">
    <div class="videobg">
      <div class="overlay"></div>
      <video autoplay muted loop objectFit="cover" style="width:100%;height:100%;object-fit: cover">
        <source src="@/assets/img/bg.mp4" type="video/mp4">
      </video>
    </div>
      <!-- 顶部 -->
        <div class="top">
          <div class="top_title">产业数据分析</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>

        <div class="cont">
            <!-- 左中 -->
        <div class="contLeft">
          <div class="nav_left">
            <div class="flex_space flex_align" style="width: 503px;">
              <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item,index)">
                <div class="nav_left_img"><img  :src="item.icon" style="width:100%;height:100%"/></div>
                <div class="nav_left_name">{{item.name}}</div>
                <div class="nav_left_num">{{ item.num || 0 }}</div>
              </div>
              <img src='@/assets/img/return.png'  style="height:70px;width:70px;margin-left:8px;z-index:100;display:block" @click="goHome()"/>
            </div>
          </div>
          <!-- 左侧 -->
          <div class="nsLeft">

            <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">产业介绍</div>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top">
                  </div>
                  <div class="panel_cun">
                    <div style="width: 320px;height: 228px;">
                      <div class="panel" style="display: flex; justify-content: center;">
                        <video @click="openVideoPopup" id="myVideo" autoplay muted loop controls :style="{ width: videoWidth + 'px', height: videoHeight + 'px' }">
                          <source src="https://xiaopingfang.baize-agri.cn/prod/api/resources/xpfxcml.mp4" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- 农业 -->
          <div class="nsright" style="margin-top: 15px;">
            <div class="left-center">
              <div class="flex_space flex_center_align" style="margin-left:24px">
                <div class="lc_title">农业</div>
                <img style="margin-right: 34px" src="../assets/img/rarrow.png" @click="goPath('industryAgriculture')"/>
              </div>
              <div class="line"></div>
              <div class="panel">
                <div class="panel_top">
                  <div style=" width: 356px;height: 131px;display: flex">
                    <div style=" width: 168px;height: 94px">
                      <div class="panel_title">{{nyInfo.zyhyName}}</div>
                      <div class="p_new_line"></div>
                      <div style="margin-top: 10px">
                        <div v-for="(item, index) in nyInfo.zyhyValue" :key="index">
                          <span class="newChar">{{item}}</span>
                        </div>
                      </div>
                    </div>
                    <div style=" width: 168px;height: 94px;margin-left: 20px">
                      <div class="panel_title">{{nyInfo.dbqyName}}</div>
                      <div class="p_new_line"></div>
                      <div style="margin-top: 10px">
                        <div v-for="(item, index) in nyInfo.dbqyValue" :key="index">
                          <span class="newChar">{{item}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="width: 355px;height: 61px;display: flex;
                  background: linear-gradient(90deg, #1C2D5C 0%, rgba(13, 24, 54, 0) 100%);margin-left: 20px">
                  <div class="p_font" style="line-height: 61px;margin-left: 24px">{{nyInfo.zczName}}</div>
                  <div style="line-height: 61px;margin-left: 190px">
                    <span class="muNum">{{nyInfo.zcz}}</span>
                  </div>
                  <div style="line-height: 70px;margin-left: 14px">
                    <span class="mu p_font">亿元</span></div>
                </div>
                <div class="panel_cun_new">
                  <div class="ageVue">
                    <div class="echartsBg">
                      <div class="age_num">{{nyInfo.tzbName}}</div>
                    </div>
                    <div  class="circle" ref="agroCircle" style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"></div>
                    <div class="echartsLeg">
                      <div class="flex_start">
                        <div class="circle" style="border-color:#F9B814"></div>
                        <div>
                          <div class="panel_cun_name">{{nyInfo.pieDataOne.name}}</div>
                          <div class="panel_cun_num">{{nyInfo.pieDataOne.value}}%</div>
                        </div>
                      </div>
                      <div class="flex_start">
                        <div class="circle" style="border-color:#9523CB"></div>
                        <div>
                          <div class="panel_cun_name">{{nyInfo.pieDataTwo.name}}</div>
                          <div class="panel_cun_num">{{nyInfo.pieDataTwo.value}}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="contCenter">
          <div class="nscenter">
            <!-- 地图 -->
            <div class="mapCss mapCity">
              <div ref="mapBox" style="width:100%;height:100%"> </div>
            </div>
            <div class="center_bottom">
            </div>
          </div>
        </div>

            <!-- 右 -->
        <div class="contRight">
          <div class="nsright">
            <div class="left-center">
              <div class="flex_space flex_center_align" style="margin-left:24px">
                <div class="lc_title">工业</div>
                <img class="lc_right" src="../assets/img/rarrow.png" @click="goPath('worker')"/>
              </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top">
                    <div style=" width: 356px;height: 131px;display: flex">
                      <div style=" width: 168px;height: 94px">
                        <div class="panel_title">{{gyInfo.zyhyName}}</div>
                        <div class="p_new_line"></div>
                        <div style="margin-top: 10px">
                          <div v-for="(item, index) in gyInfo.zyhyValue" :key="index">
                            <span class="newChar">{{item}}</span>
                          </div>
                        </div>
                      </div>
                      <div style=" width: 168px;height: 94px;margin-left: 20px">
                        <div class="panel_title">{{gyInfo.dbqyName}}</div>
                        <div class="p_new_line"></div>
                        <div style="margin-top: 10px">
                          <div v-for="(item, index) in gyInfo.dbqyValue" :key="index">
                            <span class="newChar">{{item}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 355px;height: 61px;display: flex;
                  background: linear-gradient(90deg, #1C2D5C 0%, rgba(13, 24, 54, 0) 100%);margin-left: 20px">
                    <div class="p_font" style="line-height: 61px;margin-left: 24px">{{gyInfo.zczName}}</div>
                    <div style="line-height: 61px;margin-left: 156px">
                      <span class="muNum">{{gyInfo.zcz}}</span>
                    </div>
                    <div style="line-height: 70px;margin-left: 14px">
                      <span class="mu p_font">亿元</span></div>
                  </div>
                  <div class="panel_cun_new">
                    <div class="ageVue">
                      <div class="echartsBg">
                        <div class="age_num">{{gyInfo.tzbName}}</div>
                      </div>
                      <div  class="circle" ref="industryCircle" style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"></div>
                      <div class="echartsLeg">
                        <div class="flex_start">
                          <div class="circle" style="border-color:#F9B814"></div>
                          <div>
                            <div class="panel_cun_name">{{gyInfo.pieDataOne.name}}</div>
                            <div class="panel_cun_num">{{gyInfo.pieDataOne.value}}%</div>
                          </div>
                        </div>
                        <div class="flex_start">
                          <div class="circle" style="border-color:#9523CB"></div>
                          <div>
                            <div class="panel_cun_name">{{gyInfo.pieDataTwo.name}}</div>
                            <div class="panel_cun_num">{{gyInfo.pieDataTwo.value}}%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
            </div>
          </div>
          <div class="nsright" style="margin-top: 33px;">
            <div class="left-center">
              <div class="flex_space flex_center_align" style="margin-left:24px">
<!--                服务业-->
                <div class="lc_title">{{fwyInfo.title}}</div>
                <img class="lc_right" src="../assets/img/rarrow.png" @click="goPath('industryService')"/>
              </div>
              <div class="line"></div>
              <div class="panel">
                <div class="panel_top">
                  <div style=" width: 356px;height: 131px;display: flex">
                    <div style=" width: 168px;height: 94px">
                      <div class="panel_title">{{fwyInfo.zyhyName}}</div>
                      <div class="p_new_line"></div>
                      <div style="margin-top: 10px">
                        <div v-for="(item, index) in fwyInfo.zyhyValue" :key="index">
                          <span class="newChar">{{item}}</span>
                        </div>
                      </div>
                    </div>
                    <div style=" width: 168px;height: 94px;margin-left: 20px">
                      <div class="panel_title">{{fwyInfo.dbqyName}}</div>
                      <div class="p_new_line"></div>
                      <div style="margin-top: 10px">
                        <div v-for="(item, index) in fwyInfo.dbqyValue" :key="index">
                          <span class="newChar">{{item}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 355px;height: 61px;display: flex;
                  background: linear-gradient(90deg, #1C2D5C 0%, rgba(13, 24, 54, 0) 100%);margin-left: 20px">
                  <div class="p_font" style="line-height: 61px;margin-left: 24px">{{fwyInfo.zczName}}</div>
                  <div style="line-height: 61px;margin-left: 156px">
                    <span class="muNum">{{fwyInfo.zcz}}</span>
                  </div>
                  <div style="line-height: 70px;margin-left: 14px">
                    <span class="mu p_font">万元</span></div>
                </div>
                <div class="panel_cun_new">
                  <div class="ageVue">
                    <div class="echartsBg">
                      <div class="age_num">{{fwyInfo.tzbName}}</div>
                    </div>
                    <div  class="circle" ref="serviceCircle" style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"></div>
                    <div class="echartsLeg">
                      <div class="flex_start">
                        <div class="circle" style="border-color:#F9B814"></div>
                        <div>
                          <div class="panel_cun_name">{{fwyInfo.pieDataOne.name}}</div>
                          <div class="panel_cun_num">{{fwyInfo.pieDataOne.value}}%</div>
                        </div>
                      </div>
                      <div class="flex_start" v-if="fwyInfo.pieDataTwo.name">
                        <div class="circle" style="border-color:#9523CB"></div>
                        <div>
                          <div class="panel_cun_name">{{fwyInfo.pieDataTwo.name}}</div>
                          <div class="panel_cun_num">{{fwyInfo.pieDataTwo.value}}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
   <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 9999!important" v-if="showOne" >
     <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" >
       <div style="height: 42px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
         <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
         <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close">×</span>
       </div>
       <div style="height: 5px;width: 100%;"></div>
       <div style="height: 500px;width: 100%;padding: 25px 0 25px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
         <video style="width: 100%;height: 100%;" autoplay muted loop controls class="video" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/village/qcjssp.mp4"></video>
       </div>
     </div>
   </div>
   <newMode ref="newMode"></newMode>
 </v-scale-screen>
</div>
</template>
<script>
import newMode from '@/components/newMode.vue'
import { mapOption, barOptions, newlineOption, newMapOption } from '../utils/options'
import { mapdata, geoCoordMap, coordinates } from '../utils/mapCenterPoint'
import { vipCarDateList, vipCarList, postLogin, getDangIndex, getHomeDetail, getLineClass, articleInfoList, getBankList, searchReadNums } from '@/api/api.js'
const mapData = require('../utils/newcun.json')
import * as echarts from 'echarts'
import { setTimeout } from 'timers'

export default {
  components: {
    newMode
  },
  data() {
    return {
      showOne: false,
      videoWidth: 320,
      videoHeight: 228,
      userInfo: {},
      // 工业
      gyInfo: {
        title: undefined,
        zyhyName: undefined,
        zyhyValue: [],
        dbqyName: undefined,
        dbqyValue: [],
        zczName: undefined,
        zcz: undefined,
        dw: undefined,
        tzbName: undefined,
        pieDataOne: {},
        pieDataTwo: {}
      },
      // 农业
      nyInfo: {
        title: undefined,
        zyhyName: undefined,
        zyhyValue: [],
        dbqyName: undefined,
        dbqyValue: [],
        zczName: undefined,
        zcz: undefined,
        dw: undefined,
        tzbName: undefined,
        pieDataOne: {},
        pieDataTwo: {}
      },
      // 服务业
      fwyInfo: {
        title: undefined,
        zyhyName: undefined,
        zyhyValue: [],
        dbqyName: undefined,
        dbqyValue: [],
        zczName: undefined,
        zcz: undefined,
        dw: undefined,
        tzbName: undefined,
        pieDataOne: {},
        pieDataTwo: {}
      },

      leftList: [
        { name: '民众心声', icon: require('../assets/img/imz.png'), type: '3', num: 0, ptype: 'voxPop' },
        {
          name: '全民广场',
          icon: require('../assets/img/iqm.png'),
          type: '2',
          num: 0,
          ptype: 'nationalSquare'
        },
        {
          name: '在线课堂',
          icon: require('../assets/img/isk.png'),
          type: '1',
          num: 0,
          ptype: 'readBook'
        },
        {
          name: '道德银行',
          icon: require('../assets/img/ibank.png'),
          type: '4',
          num: 0,
          ptype: 'releaseReq'
        },
        {
          name: '贵客到访',
          icon: require('../assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [{}, {}, {}, {}],
      total: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0, impatientNum: 0 },
      nerwstimer: ''
    }
  },
  methods: {
    // 视频弹窗
    openVideoPopup() {
      this.showOne = true
    },
    // 关闭预览
    close() {
      this.showOne = false
    },
    goHome() {
      this.$router.replace('/integrated')
      setTimeout(function() {
        window.location.reload()
      }, 30)
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map.clear()
      // map.setOption(newMapOption)
    },
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['排放', '投入']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data
          }
        ]
      }
      let cricle = echarts.init(this.$refs.industryCircle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    drowSerCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['排放', '投入']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data
          }
        ]
      }
      let cricle = echarts.init(this.$refs.serviceCircle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    drowAgroCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['排放', '投入']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data
          }
        ]
      }
      let cricle = echarts.init(this.$refs.agroCircle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },

    getHomeDetail(regionId) {
      getHomeDetail({ regionId }).then(res => {
        if (res.code == 0) {
          this.$nextTick(() => {
            let dataList = res.model
            if (dataList) {
              this.handleData(dataList, 'right-bottom')
              this.handleData(dataList, 'left-bottom')
              this.handleData(dataList, 'right-top')
            }
          })
        }
      })
    },
    handleData(dataList, type) {
      let commonObj = {
        title: undefined,
        zyhyName: undefined,
        zyhyValue: [],
        dbqyName: undefined,
        dbqyValue: [],
        zczName: undefined,
        zcz: undefined,
        dw: undefined,
        tzbName: undefined,
        pieDataOne: {},
        pieDataTwo: {}
      }
      let fwyItem = dataList.find(item => item.position === type)
      commonObj.title = fwyItem.title

      // 1.主要行业
      let hyItem = fwyItem.detailList[0]
      commonObj.zyhyName = hyItem.elementName
      if (JSON.parse(hyItem.elementValue).value) {
        commonObj.zyhyValue = JSON.parse(hyItem.elementValue).value.split('、')
      }
      // 2.代表企业
      let qyItem = fwyItem.detailList[1]
      commonObj.dbqyName = qyItem.elementName
      if (JSON.parse(qyItem.elementValue).value) {
        commonObj.dbqyValue = JSON.parse(qyItem.elementValue).value.split('、')
      }
      // 3.总产值
      let zczItem = fwyItem.detailList[2]
      commonObj.zczName = zczItem.elementName
      if (JSON.parse(zczItem.elementValue).value) {
        let zcz = JSON.parse(zczItem.elementValue).value
        commonObj.zcz = parseFloat(zcz)
        // commonObj.dw = this.GetChinese(zcz)
      }
      // 4.碳指标
      let tzbItem = fwyItem.detailList[3]
      commonObj.tzbName = tzbItem.elementName
      let obj = JSON.parse(tzbItem.elementValue)
      // eslint-disable-next-line no-empty
      if (type === 'right-bottom') {
        // 服务业饼图
        this.drowSerCircle(obj.pieData)
        // eslint-disable-next-line no-empty
      } else if (type === 'right-top') {
        // 工业饼图
        this.drowCircle(obj.pieData)
        // eslint-disable-next-line no-empty
      } else if (type === 'left-bottom') {
        // 农业饼图
        this.drowAgroCircle(obj.pieData)
      }
      commonObj.pieDataOne = obj.pieData[0]
      commonObj.pieDataTwo = obj.pieData[1]
      let sum = Number(obj.pieData[0].value) + Number(obj.pieData[1].value)
      let onePercent = this.drawProgressNum(Number(obj.pieData[0].value), sum)
      commonObj.pieDataOne.value = onePercent
      let twoPercent = this.drawProgressNum(Number(obj.pieData[1].value), sum)
      commonObj.pieDataTwo.value = twoPercent
      // eslint-disable-next-line no-empty
      if (type === 'right-bottom') {
        this.fwyInfo = commonObj
        // eslint-disable-next-line no-empty
      } else if (type === 'right-top') {
        this.gyInfo = commonObj
        // eslint-disable-next-line no-empty
      } else if (type === 'left-bottom') {
        this.nyInfo = commonObj
      }
    },
    // 只提取汉字
    GetChinese(strValue) {
      if (strValue !== null && strValue !== '') {
        const reg = /[\u4e00-\u9fa5]/g
        return strValue.match(reg).join('')
      }
      return ''
    },
    drawProgressNum(num, total) {
      let leftWidth = num / total * 100
      //将百分比保留两位小数
      let percentNum = (Math.round(leftWidth * 100) / 100).toFixed(2)
      return percentNum
    },
    //登录
    submitForm() {
      let that = this
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      this.userInfo = {}
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            sessionStorage.setItem('token', res.model.tokenValue)
            this.userInfo = res.model
            that.$nextTick(() => {
              that.getHomeDetail(this.userInfo.regionId)
            })
          } else {
            that.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    showMode(item, index) {
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item, index)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item, index)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }

      this.clearRead(item.ptype)
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data, index) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item, index) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    goPath(name) {
      this.$router.push({ name: name })
    },
    showMessage(content) {
      this.content = content
      this.show = true
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
          this.content = {}
          this.show = false
        }, 10000) // 3秒后隐藏消息
      }
    },
    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            //贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    }
  },

  mounted() {
    this.submitForm()
    this.$nextTick(() => {
      //静态数据echarts
      setTimeout(() => {
        this.initChart()
      }, 1000) // 延迟 100 毫秒调用
    })

    //消息推送
    this.searchNews()
    this.getReadnums()
  },
  destroyed() {
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'JDLangZhengTi';
  src: url('../assets/fonts/JDLangZhengTi.ttf') format('truetype');
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('../assets/fonts/ShiShangZhongHeiJianTi.ttf') format('truetype');
}

// 公共
.lc_title {
  font-family: JDLangZhengTi;
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  margin-left: 21px;
  position: relative;
}

.lc_title::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 32px;
  background: #0099ff;
  box-shadow: 0px 0px 13px 1px #0099ff;
  position: absolute;
  top: 0;
  left: -21px;
}
.lc_right {
  margin-right: 39px;
  cursor: pointer;
}
.panel {
  width: 100%;
  height: auto;
  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
  overflow: hidden;
  clear: both;
  .panel_top {
    padding: 27px 0 0 22px;
  }
  .panel_title {
    font-family: JDLangZhengTi;
    font-size: 20px;
    font-weight: 400;
    color: #0099ff;
    margin-bottom: 17px;
  }
  .p_line {
    width: 319px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
  .p_new_line {
    width: 168px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
  .p_font {
    font-size: 12px;
    font-weight: 400;
  }
}
.line {
  background: linear-gradient(90deg, #0780ae 0%, rgba(7, 128, 174, 0) 100%);
  height: 2px;
  margin-top: 21px;
}
.newIndustryScreen {
  width: 100%;
  height: 100%;
  // background-image: url('./assets/img/bg1.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  color: #fff;
  .videobg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(41, 6, 22, 0.8), rgba(8, 16, 64, 0.8));
  }
  .top_title {
    font-family: JDLangZhengTi;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #c4e6fe;
    text-shadow: #0099ff 0px 0px 15px;
    width: 1471.5px;
    height: 83px;
    line-height: 83px;
    margin: 0 auto;
    background-image: url('../assets/img/itop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .top_title_people {
    width: 253px;
    height: 47px;
    margin: -10px auto 0;
    background-image: url('../assets/img/itop2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-shadow: #0099ff 0px 0px 15px;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 13px;
      margin-left: 25px;
      margin-top: 5px;

      .left {
        height: 40px;
        width: 40px;
      }

      .right {
        height: 13px;
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .bottom {
      height: 22px;
      line-height: 22px;
      font-family: ShiShangZhongHeiJianTi;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(255, 255, 255, 1);
      margin-right: 35px;
      margin-top: 5px;
      // box-shadow: 0px 0px 10px 1px rgba(0, 153, 255, 1);
    }
  }
  .cont {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -84px;
    position: relative;
  }
  .nav_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 46px;
    top: 0;
    &_item {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
    }
    &_img {
      width: 59px;
      height: 59px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
    }
    &_name {
      font-family: JDLangZhengTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
    &_num {
      position: absolute;
      top: -6px;
      right: 8px;
      z-index: 99;
      background: #ff6b00;
      border-radius: 15px;
      text-align: center;
      font-family: JDLangZhengTi;
      font-size: 8px;
      font-weight: 400;
      min-width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
    }
  }
  .nav_right {
    position: absolute;
    right: 81px;
    top: 0;
    width: 410px;
  }
  .nsLeft {
    width: 400px;
    margin-top: 119px;
    .left-center .panel_cun {
      width: 349px;
      height: 272px;
      padding: 35px 0 58px 34px;
      .panel_cun_box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
      .panel_cun_name {
        font-size: 12px;
        line-height: 15.96px;
        margin-left: 6px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-left: 6px;
      }
      .ageVue {
        width: 285px;
        height: 195px;
        position: relative;
        //overflow: hidden;
        margin-top: 40px;
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          // letter-spacing: 0em;
          // text-align: center;
          text-shadow: 0px 1px 10px #0099ff;
        }
      }
    }
    .line_box {
      width: 345px;
      height: 194px;
      margin: 30px 0 22px 13px;
    }
  }
  .nscenter {
    width: 1103px;
    margin-top: 147px;
    .mapCity {
      width: 710px;
      margin: 0 auto;
      height: 507px;
    }
    .center_bottom {
      display: flex;
      align-items: flex-start;
      .center_bleft {
        flex: 1.5;
        margin-right: 27px;
        margin-left: 3px;
      }
      .center_nright {
        flex: 1;
        margin-right: 24px;
      }
    }
  }
  .nsright {
    width: 400px;
    height: 435px;
    margin-top: 119px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
      }
    }
    .newChar {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-shadow: 0px 1px 10px #0099ff;
    }
  }
}
.muNum {
  font-family: ShiShangZhongHeiJianTi;
  font-size: 30px;
  font-weight: 400;
  text-shadow: 0px 1px 10px #0099ff;
}
.mu {
  color: #0099ff;
}
//
.panel_cun_new {
  width: 355px;
  height: 142px;
  padding: 0px 0px 0px 34px;
  .panel_cun_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 5px solid #ffffff;
  }
  .panel_cun_name {
    font-size: 12px;
    line-height: 15.96px;
    margin-left: 6px;
  }
  .panel_cun_num {
    font-family: ShiShangZhongHeiJianTi;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-left: 6px;
  }
  .ageVue {
    width: 254px;
    height: 120px;
    position: relative;
    // overflow: hidden;
    margin-top: 38px;
    .echartsBg {
      position: absolute;
      width: 78%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .age_img {
      width: 42px;
      height: 42px;
    }
    .age_num {
      font-family: ShiShangZhongHeiJianTi;
      font-size: 12px;
      font-weight: 400;
      line-height: 38px;
      // letter-spacing: 0em;
      // text-align: center;
      text-shadow: 0px 1px 10px #0099ff;
    }
    .echartsLeg {
      position: absolute;
      right: -47px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .panel_cun_name {
        font-size: 14px;
        line-height: 18.48px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        text-shadow: 0px 1px 10px #0099ff;
        margin-top: 4px;
      }
    }
  }
}
.nav_right .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #ceb77b !important;
  height: 0.15rem !important;
}
.nav_right .el-table .el-table__cell {
  padding: 0 !important;
  height: 0.15rem !important;
  font-size: 0.12rem;
}
.nav_right .el-table thead {
  color: #fff !important;
  font-size: 0.12rem !important;
}
.nav_right > .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.nav_right .el-table tr {
  color: #fff !important;
  background: transparent !important;
}

/* 用来设置当前页面element全局table 选中某行时的背景色*/
.nav_right .el-table__body tr.current-row > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
.nav_right .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.nav_right .el-table__body-wrapper {
  overflow: hidden !important;
}
.nav_right .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}
.nav_right .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.nav_right .el-table .cell {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.nav_right .el-table::before {
  height: 0 !important;
}
</style>